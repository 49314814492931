import React from 'react';

import {
  YotpoProductReviewsWidget,
  widgetTypes,
} from '~/components/products/yotpo-product-reviews-widget';

export const SMART_LAWN_PLAN_YOTPO_PRODUCT_ID = '456723';

const SmartLawnPlanReviews = ({ widget = widgetTypes.MAIN }) => {
  return (
    <YotpoProductReviewsWidget
      productId={SMART_LAWN_PLAN_YOTPO_PRODUCT_ID}
      productName="Smart Lawn Plan"
      productPageUrl="https://www.getsunday.com/smart-lawn-plan"
      productImageUrl="https://www.getsunday.com/images/unboxing-bottom-400w.png?v1"
      widget={widget}
    />
  );
};

export default SmartLawnPlanReviews;
